<template>
  <section>
    <!-- main info block -->
    <v-card elevation="2" class="account-page__card">
      <account-header
        :key="`account-header-`"
        :can-edit="!isProfessionalRole"
        :component-key="componentKey"
        @handle-header-data="handleHeaderData"
      />
      <account-body :key="`account-body-`" :header-data="headerData" @handle-cancel="componentKey++" />
    </v-card>
    <!-- security related block -->
    <v-card elevation="2" class="account-page__card mt-6">
      <account-security />
    </v-card>

    <v-card v-if="isProfessionalRole" elevation="2" class="account-page__card mt-6">
      <AccountStatus :professional-id="professionalId"/>
    </v-card>

    <v-card elevation="2" class="account-page__card mt-6">
      <AccountConditions />
    </v-card>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import AccountHeader from '@/components/account/layout/AccountHeader';
import AccountBody from '@/components/account/layout/AccountBody';
import AccountSecurity from '@/components/account/layout/AccountSecurity';
import AccountConditions from "@/components/account/layout/AccountConditions.vue";
import AccountStatus from "@/components/account/layout/AccountStatus.vue";
import {authUserService} from "@/services/auth-user";

export default {
  name: 'AccountView',

  components: {
    AccountHeader,
    AccountBody,
    AccountSecurity,
    AccountConditions,
    AccountStatus,
  },

  data() {
    return {
      headerData: null,
      componentKey: 0,
    };
  },

  computed: {
    ...mapGetters({
      isProfessionalRole: 'account/isProfessionalRole',
    }),
    professionalId() {
      return authUserService.get().user.professional_id;
    },
  },

  methods: {
    handleHeaderData(value) {
      if (!value) {
        return;
      }

      this.headerData = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.account-page {
  &__title {
    font-size: rem(21);
    font-weight: 400;
  }
}
</style>
